import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Login from "../components/Login/Login.js";
import Dashboard from "../components/Dashboard/Dashboard.js";
import SideNav from "../components/SideNavBar/SideNav.js";
import Layout from "../components/layout/Layout.js";
import ApplicationDetails from "../components/GlobalSettings/Applications/ApplicationDetails/ApplicationDetails.js";
import TransactionList from "../components/Transactions/TransactionList.js";
import TransactionDetails from "../components/Transactions/TransactionDetails.js";
import WalletList from "../components/Wallets/WalletList.js";
import WalletDetails from "../components/Wallets/WalletDetails.js";
import GlobalSettings from "../components/GlobalSettings/GlobalSettings.js";
import CentralisedWalletsList from "../components/CentralisedWallets/CentralisedWalletsList.js";
import CentralisedWalletDetails from "../components/CentralisedWallets/CentralisedWalletDetails.js";
import Sweeps from "../components/Sweeps/Sweeps.js";
import ApplicationUserDetails from "../components/GlobalSettings/Applications/ApplicationDetails/ApplicationUserDetails.js";
import Notifications from "../components/Notifications/Notifications.js";

const RoutesList = () => {
  const userRole = localStorage.getItem("role");

  return (
    <Router>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route
          exact
          path="/"
          element={
            <Layout>
              <SideNav children={<Dashboard />} />
            </Layout>
          }
        />
        <Route
          path="/applications/:id"
          element={
            <Layout>
              <SideNav children={<ApplicationDetails />} />
            </Layout>
          }
        />
        <Route
          path="/transactions"
          element={
            <Layout>
              <SideNav children={<TransactionList />} />
            </Layout>
          }
        />
        <Route
          path="/transactions/:id"
          element={
            <Layout>
              <SideNav children={<TransactionDetails />} />
            </Layout>
          }
        />
        <Route
          path="/centralised-wallets"
          element={
            <Layout>
              <SideNav children={<CentralisedWalletsList />} />
            </Layout>
          }
        />
        <Route
        path="/centralised-wallets/:addressKey"
        element={
          <Layout>
            <SideNav children={<CentralisedWalletDetails />} />
          </Layout>
        }
        />
        <Route
          path="/wallets"
          element={
            <Layout>
              <SideNav children={<WalletList />} />
            </Layout>
          }
        />
        <Route
          path="/wallets/:addressKey"
          element={
            <Layout>
              <SideNav children={<WalletDetails />} />
            </Layout>
          }
        />
        <Route
          path="/sweeps"
          element={
            <Layout>
              <SideNav children={<Sweeps />} />
            </Layout>
          }
        />
        <Route
          path="/global-settings"
          element={
            <Layout>
              <SideNav children={userRole === "ApplicationUser" ? <ApplicationUserDetails /> : <GlobalSettings />} />
            </Layout>
          }
        />
        <Route
          path="/notifications"
          element={
            <Layout>
              <SideNav children={<Notifications/>} />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
};

export default RoutesList;
