import * as React from "react";
import {
  Box,
  Drawer as MuiDrawer,
  CssBaseline,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  List,
} from "@mui/material";
import { DragHandle } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  zIndex: 1301, // Ensures DrawerHeader is above the AppBar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: "#97180C", // Set the Drawer background color
  zIndex: 1300, // Higher z-index to overlap the AppBar
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      backgroundColor: "#36454F", // Set the Drawer background color
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      backgroundColor: "#36454F", // Set the Drawer background color
    },
  }),
}));

const SideNav = ({ heading, children }) => {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate(); // Use the hook here
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  const handleOpenClose = () => {
    setOpen(!open);
  };

  const menuItems = [
    {
      text: "Dashboard",
      icon: (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/nav_dashboard.png`}
          alt="dashboardIcon"
          className="w-6 h-6"
        />
      ),
      path: "/",
    },
    {
      text: "Transactions",
      icon: (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/nav_transaction.png`}
          alt="tarnsactionsIcon"
          className="w-6 h-6"
        />
      ),
      path: "/transactions",
    },
    {
      text: "Centralised Wallets",
      icon: (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/centralised-wallet.png`}
          alt="walletsIcon"
          className="w-6 h-6"
        />
      ),
      path: "/centralised-wallets",
    },
    {
      text: "Wallets",
      icon: (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/nav_wallet.png`}
          alt="walletsIcon"
          className="w-6 h-6"
        />
      ),
      path: "/wallets",
    },
    {
      text: "Sweeps",
      icon: (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/nav_sweeps.png`}
          alt="sweepsIcon"
          className="w-6 h-6"
        />
      ),
      path: "/sweeps",
    },
    {
      text: "Global Settings",
      icon: (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/nav_setting.png`}
          alt="settingsIcon"
          className="w-6 h-6"
        />
      ),
      path: "/global-settings",
    },
    {
      text: "Notifications",
      icon: (
        <div className="relative">
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/notifications_nav.png`}
            alt="notificationsIcon"
            className="w-6 h-6"
          />
          <p className="absolute top-0 right-1 w-2 h-2 bg-amber-400 rounded-full"></p>
        </div>
      ),
      path: "/notifications",
    },
  ];

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const isActive = (path) => {
    if (path === "/") {
      return location.pathname === path; // Exact match for root (Dashboard)
    }
    return location.pathname.startsWith(path); // Matches if the path starts with '/transactions' (for dynamic routes like /transactions/:id)
  };

  return (
    <Box className="flex">
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          sx={{
            justifyContent: "center",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
          }}
        >
          {open && (
            <>
              <Box
                className="w-48 cursor-pointer"
                onClick={handleOpenClose}
                component="img"
                src="/navImage.png"
                alt="Logo"
              />
            </>
          )}
          {!open && (
            <IconButton onClick={handleOpenClose}>
              <DragHandle sx={{ color: "white" }} fontSize="large" />
            </IconButton>
          )}
        </DrawerHeader>

        <Divider />
        <List className="h-full flex flex-col justify-between">
          <div>
            {menuItems.map(({ text, icon, path }) => {
              const active = isActive(path); // Check if the current path is active
              return (
                <ListItem
                  key={text}
                  disablePadding
                  sx={{ display: "block" }}
                  className="px-2.5 mt-1 first:mt-0"
                >
                  <ListItemButton
                    component={Link}
                    to={path}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      backgroundColor:
                        active ? "#808080" : "inherit", // Selection color
                      "&:hover": {
                        backgroundColor: "#808080",// Hover color
                      },
                      color: active ? "white" : "inherit",
                      borderRadius: 1,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: active ? "white" : "inherit",
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text}
                      sx={{
                        opacity: open ? 1 : 0,
                        color: active ? "white" : "inherit",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              )
            })}
          </div>

          <div>
            <ListItem
              key={"Logout"}
              disablePadding
              sx={{ display: "block" }}
              className="px-3 mb-4"
            >
              <ListItemButton
                onClick={logout}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  "&:hover": {
                    backgroundColor: "#BC1B06",
                  },
                  color: "white",
                  borderRadius: 1,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "inherit", // Icon color
                  }}
                >
                  <img
                    src={`${imageBaseURL}/images/caas-admin-ui/icons/nav_logout.png`}
                    alt="walletsIcon"
                    className="w-6 h-6"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"Logout"}
                  sx={{
                    opacity: open ? 1 : 0,
                    color: "inherit", // Text color
                  }}
                />
              </ListItemButton>
            </ListItem>
          </div>
        </List>
      </Drawer>
      <Box
        component="main"
        className="overflow-auto flex-grow p-3 relative h-screen"
      >
        {children}
      </Box>
    </Box>
  );
};

export default SideNav;
