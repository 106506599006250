import React from 'react';
import { FormControl, Select, MenuItem, FormHelperText } from '@mui/material';

const CommonDropDown = ({ placeHolder, options, value, onChange, name, image, required, onBlur, touched, errors, isMultiple, disabled }) => {
    return (
        <FormControl variant="outlined" required = {Boolean(required)} >
            <Select
                name={name}
                value={value}
                onChange={onChange}
                className="bg-white filter-select"
                displayEmpty
                size='small'
                placeholder={placeHolder}
                onBlur={onBlur && onBlur}
                disabled= {Boolean(disabled)}
                multiple={Boolean(isMultiple)}
            >
                <MenuItem disabled value="">
                    <em className='flex gap-2 items-center'>{image}{placeHolder}</em>
                </MenuItem>
                {options?.map((option, index) => (
                    <MenuItem key={index} value={option?.value}>
                        {option?.label}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText className="!text-red-600">
                    {touched && errors}
            </FormHelperText>
        </FormControl>
    );
};

export default CommonDropDown;
