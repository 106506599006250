const transactionCategories = ["Buy", "Sell", "Receive", "Send", "Exchange"];

const walletBlockchain = [
  { blockchain: "Bitcoin", key: "Bitcoin" },
  { blockchain: "Ethereum", key: "Ethereum" },
  { blockchain: "Litecoin", key: "Litecoin" },
  { blockchain: "Ripple", key: "Ripple" },
  { blockchain: "Tron", key: "Tron" },
];

const walletCrypto = [
  { crypto: "Ethereum", key: "ETH" },
  { crypto: "Litecoin", key: "LTC" },
  { crypto: "Ripple", key: "XRP" },
  { crypto: "Tron", key: "TRX" },
  { crypto: "DAI", key: "DAI" },
  { crypto: "USDT", key: "USDT" },
  { crypto: "USDC", key: "USDC" },
  { crypto: "Bitcoin", key: "BTC" },
];

const constant = { transactionCategories, walletBlockchain, walletCrypto };
export default constant;
