import React from "react";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import CommonDropDown from "../common/CommonDropDown.js"

const SweepConfigurationFilter = (props) => {
    const {inputFields, cryptoCurrencyList, handleInputChange, onSubmit, reset } = props;
    const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

    const cryptoOptions = [
        { value: 'All', label: 'All' },
        { value: false, label: 'False' },
        { value: true, label: 'True' },
    ];

    return (
        <Box className="flex flex-col justify-between h-full sweep-configuration-filter">
            <Box className="mt-8 filter-drawer flex gap-4">
               
                <div className="flex flex-col gap-2">
                    <p className="text-zinc-600">Crypto</p>
                    <CommonDropDown
                        options={[ ...(cryptoCurrencyList?.map(item=>({value: item?._id, label: item?.symbol })) || [])]}
                        value={inputFields?.crypto}
                        onChange={handleInputChange}
                        isMultiple={true}
                        placeHolder={"Select Crypto"}
                        name="crypto" />
                </div>
                <div className="flex flex-col gap-2">
                    <p className="text-zinc-600">Take All Funds</p>
                    <CommonDropDown
                        options={cryptoOptions}
                        value={inputFields?.takeAllFunds}
                        onChange={handleInputChange}
                        name="takeAllFunds" />
                </div>
                <div className="flex flex-col gap-2">
                    <p className="text-zinc-600">Archive</p>
                    <CommonDropDown
                        options={cryptoOptions}
                        value={inputFields?.archive}
                        onChange={handleInputChange}
                        name="archive" />
                </div>
                <div className="flex flex-col gap-2">
                    <p className="text-zinc-600">Sweep Flag</p>
                    <CommonDropDown
                        options={cryptoOptions}
                        value={inputFields?.sweepFlag}
                        onChange={handleInputChange}
                        name="sweepFlag" />
                </div>
                <div className="flex flex-col gap-2">
                    <p className="text-zinc-600">Config Flag</p>
                    <CommonDropDown
                        options={cryptoOptions}
                        value={inputFields?.configFlag}
                        onChange={handleInputChange}
                        name="configFlag" />
                </div>
               
            </Box>

            <Box className="flex self-end gap-2">
                <Button size="large" variant="outlined" id="reset" onClick={reset}>
                    <img
                        src={`${imageBaseURL}/images/caas-admin-ui/icons/reload_button.png`}
                        alt="reload-icon"
                        className="w-6 h-6 mr-2"
                    />
                    Reset
                </Button>
                <Button size="large" variant="contained" id="search" onClick={onSubmit}>
                    <img
                        src={`${imageBaseURL}/images/caas-admin-ui/icons/search_button.png`}
                        alt="search-icon"
                        className="w-6 h-6 mr-2"
                    />
                    Search
                </Button>
            </Box>
        </Box>
    );
};

export default SweepConfigurationFilter;
