import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const FiltersDrawer = ({ open, onClose, children }) => {
  const [isVisible, setIsVisible] = useState(open);
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  useEffect(() => {
    if (open) {
      setIsVisible(true);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
      setTimeout(() => setIsVisible(true), 300);
    }
  }, [open]);

  return (
    <div
      className={`z-[1300] fixed top-0 left-0 w-full h-full inset-0 transition-opacity duration-300 ease-in-out ${
        open ? "backdrop-blur-[1px]" : "opacity-0 invisible "
      } ${!isVisible && "hidden"}`}
      aria-hidden="true"
    >
      <div
        className={`relative w-full h-full overflow-y-auto rounded-t-md
                    transition-transform duration-300 ease-in-out
                    ${!isVisible && "hidden"}`}
      >
        <div
          className={`absolute bg-white w-full h-[56%] bottom-0 right-0 p-4 border
                    ${!isVisible && "hidden"}`}
          tabIndex="-1"
        >
          <div className="absolute top-2 right-2 p-3">
            <img
              src={`${imageBaseURL}/images/caas-admin-ui/icons/close.png`}
              alt="Close"
              onClick={onClose}
              className="w-6 h-6 cursor-pointer hover:bg-gray-200"
            />
          </div>

          <p className="text-black font-semibold text-lg">Filters</p>
          <div className="mt-2 h-[90%]">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default FiltersDrawer;
