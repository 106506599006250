import React from "react";
import { Pagination } from "@mui/material";
import { styled } from "@mui/material/styles/index.js";
import CommonButton from "../common/CommonButton.js";
import { useSelector } from "react-redux";

const CommonPagination = (props) => {
  const { totalPages, setPage, page } = props;
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  const CustomPagination = styled(Pagination)(({ theme }) => ({
    "& .MuiPaginationItem-previousNext": {
      display: "none",
    },
  }));

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    if (page < Math.ceil(totalPages / 10)) {
      setPage(page + 1);
    }
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  return (
    <div className="mt-2 flex justify-between p-6 w-full">
      <CommonButton
        text="Previous"
        disabled={page === 1}
        onClick={handlePrevious}
        StartIcon={
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/previous_button.png`}
            alt="prev-icon"
            className="w-5 h-5"
          />
        }
      />
      <CustomPagination
        count={Math.ceil(totalPages / 10)}
        page={page}
        onChange={handlePageChange}
        siblingCount={1}
        boundaryCount={1}
        variant="outlined"
        shape="rounded"
      />
      <CommonButton
        text="Next"
        disabled={page === Math.ceil(totalPages / 10)}
        onClick={handleNext}
        EndIcon={
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/next_button.png`}
            alt="next-icon"
            className="w-5 h-5"
          />
        }
      />
    </div>
  );
};

export default CommonPagination;
