import React from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const TransactionCard = ({ title, data }) => {
  const items = [
    {
      label: "Completed:",
      amount: data?.Completed?.amount,
      count: data?.Completed?.count,
    },
    {
      label: "Processing:",
      amount: data?.Processing?.amount,
      count: data?.Processing?.count,
    },
    {
      label: "Rejected:",
      amount: data?.Rejected?.amount,
      count: data?.Rejected?.count,
    },
    {
      label: "Failed:",
      amount: data?.Failed?.amount,
      count: data?.Failed?.count,
    },
    {
      label: "Total:",
      amount: data?.total?.amount,
      count: data?.total?.count,
    },
  ];
  return (
    <Grid
      item
      xs={12}
      md={6}
      className="border-[1px] rounded-lg md:w-[calc(33.33%-16px)] w-full"
    >
      <p className="font-semibold mt-2 ml-3">
        {title}
      </p>
      <List dense={true}>
        {items.map((item, index) => {
          return (
            <ListItem
              key={index}
              secondaryAction={
                <Box className="">
                  ${" "}
                  <span className="text-gray-600">
                    {item?.amount ? item?.amount.toFixed(6) : 0}
                  </span>{" "}
                  -{" "}
                  <span className="text-blue-600">
                    {item?.count ? item?.count : 0}
                  </span>
                </Box>
              }
              className="border-t-[1px] !py-2"
            >
              <ListItemText primary={item?.label} />
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );
};

export default TransactionCard;
