import React from "react";
import { Box, TextField, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";

const FilterTextField = (props) => {
  const { label, name, placeholder, handleInputChange, value } = props;
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  return (
    <Box>
      <p className="text-sm mb-2 font-normal text-[#51525C]">{label}</p>
      <TextField
        name={name}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img
                src={`${imageBaseURL}/images/caas-admin-ui/icons/search.png`}
                alt="search-icon"
                className="w-6 h-6 mr-2"
              />
            </InputAdornment>
          ),
        }}
        onChange={handleInputChange}
        value={value}
        size="small"
      />
    </Box>
  );
};

export default FilterTextField;
