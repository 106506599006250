import { Box, TextField, Divider } from "@mui/material";
import CommonTable from "../common/CommonTable.js";
import { styled } from "@mui/material/styles/index.js";
import TableCell, { tableCellClasses } from "@mui/material/TableCell/index.js";
import { useSelector } from "react-redux";
import TransactionDetails from "./TranctionDetails.js";
import Datepicker from "react-tailwindcss-datepicker";
import "./Notifications.css"
import { memo, useState } from "react";
import CommonDropDown from "../common/CommonDropDown.js";
import CommonPagination from "../common/CommonPagination.js";
import { Close } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const Notifications = () => {
    const [zoomIn, setZoomIn] = useState(false);
    const [page, setPage] = useState(1);
    const [date, setDate] = useState();
    const [inputFields, setInputFields] = useState({
        application: "",
        paymentProvider: "",
        crypto: "",
        minFullWalletsThresholds: "",
        minUSDThresholds: "",
        maxUSDThresholds: "",
        takeAllFunds: "",
        archive: "",
        maxFeeThresholds: "",
        maxPercentThresholds: "",
        sweepFlag: "",
        notificationStatus: "",
        notificationType: "",
    });
    const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);
    const dummyData = [{
        Event: <div className="flex items-center"> <span className="inline-block w-1.5 h-1.5 bg-red-500 rounded-full mr-1 " />"ID...x6Miz31"</div>,
        Status: <div className='bg-yellow-50 text-amber-800 rounded-2xl text-sm w-20 h-7 text-center py-1 px-3'>Rejected</div>,
        Date: "24.05.21 16:55",
        Account: "Acme Corp",
        Description: "Database connection failed during transaction processing"
    },
    {
        Event: <div className="flex items-center"> <span className="inline-block w-1.5 h-1.5 bg-red-500 rounded-full mr-1 " />"ID...x6Miz31"</div>,
        Status: <div className='bg-red-50 text-red-500 rounded-2xl text-sm w-16 h-7 text-center py-1 px-3'>Failed</div>,
        Date: "24.05.21 16:55",
        Account: "Emerald Hopkins",
        Description: "A transaction of $5,000 was successfully processed"
    },
    {
        Event: <div className="flex items-center"> <span className="inline-block w-1.5 h-1.5 bg-red-500 rounded-full mr-1 " />"ID...x6Miz31"</div>,
        Status: <div className='bg-red-50 text-red-500 rounded-2xl text-sm w-16 h-7 text-center py-1 px-3'>Failed</div>,
        Date: "24.05.21 16:55",
        Account: "Tech Supplies Inc",
        Description: "Payment gateway timeout occurred. Transaction was retried and succeeded on second attempt"
    },
    {
        Event: <div className="flex items-center"> <span className="inline-block w-1.5 h-1.5 bg-red-500 rounded-full mr-1 " />"ID...x6Miz31"</div>,
        Status: <div className='bg-yellow-50 text-amber-800 rounded-2xl text-sm w-20 h-7 text-center py-1 px-3'>Rejected</div>,
        Date: "24.05.21 16:55",
        Account: "Acme Corp",
        Description: "Database connection failed during transaction processing"
    },
    {
        Event: "ID345D...x6M",
        Status: <div className='bg-yellow-50 text-amber-800 rounded-2xl text-sm w-20 h-7 text-center py-1 px-3'>Rejected</div>,
        Date: "24.05.21 16:55",
        Account: "Emerald Hopkins",
        Description: "A transaction of $5,000 was successfully processed"
    },
    {
        Event: "ID345D...x6M",
        Status: <div className='bg-red-50 text-red-500 rounded-2xl text-sm w-16 h-7 text-center py-1 px-3'>Failed</div>,
        Date: "24.05.21 16:55",
        Account: "Acme Corp",
        Description: "Payment gateway timeout occurred. Transaction was retried and succeeded on second attempt"
    },
    {
        Event: "ID345D...x6M",
        Status: <div className='bg-yellow-50 text-amber-800 rounded-2xl text-sm w-20 h-7 text-center py-1 px-3'>Rejected</div>,
        Date: "24.05.21 16:55",
        Account: "Tech Supplies Inc",
        Description: "Database connection failed during transaction processing"
    },
    {
        Event: "ID345D...x6M",
        Status: <div className='bg-red-50 text-red-500 rounded-2xl text-sm w-16 h-7 text-center py-1 px-3'>Failed</div>,
        Date: "24.05.21 16:55",
        Account: "Acme Corp",
        Description: "Payment gateway timeout occurred. Transaction was retried and succeeded on second attempt"
    },
    {
        Event: "ID345D...x6M",
        Status: <div className='bg-yellow-50 text-amber-800 rounded-2xl text-sm w-20 h-7 text-center py-1 px-3'>Rejected</div>,
        Date: "24.05.21 16:55",
        Account: "Emerald Hopkins",
        Description: "A transaction of $5,000 was successfully processed"
    },
    {
        Event: "ID345D...x6M",
        Status: <div className='bg-yellow-50 text-amber-800 rounded-2xl text-sm w-20 h-7 text-center py-1 px-3'>Rejected</div>,
        Date: "24.05.21 16:55",
        Account: "Tech Supplies Inc",
        Description: "Database connection failed during transaction processing"
    },
    ];
    const filterItems = [
        {
            label: "Crypto ID",
            value: "LTC"
        },
        {
            label: "Change 1h",
            value: "All"
        },
        {
            label: "Change 24h",
            value: "All"
        },
        {
            label: "Price",
            value: "All"
        },
        {
            label: "Date",
            value: "All"
        },

    ];
    const tableHeaders = [
        { headerItem: "Event", sortValue: "Event" },
        { headerItem: "Status", sortValue: "Status" },
        { headerItem: "Date", sortValue: "Date" },
        { headerItem: "Account", sortValue: "Account" },
        { headerItem: "Description", sortValue: "Description" },
    ];
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputFields((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const cryptoOptions = [
        { value: 'bitcoin', label: 'Bitcoin' },
        { value: 'ethereum', label: 'Ethereum' },
    ]
    const handleZoomClick = () => {
        setZoomIn(!zoomIn);
    }
    const handleDateChange = (newDate) => {
        setDate(newDate);
    };
    return (
        <Box className="flex w-full border-[1px] rounded-lg	h-full">
            {!zoomIn ? <Box className="rounded-lg pb-4 h-full w-[70%]">
                <Box className="m-6 flex flex-col gap-6">
                    <Box className="flex gap-3 items-center">
                        <p className="font-semibold text-xl">Notifications</p>
                        <p className="bg-red-500 text-white text-xs rounded-full w-6 flex items-center justify-center">6+</p>
                    </Box>
                    <Divider className="mx-6 pt-2" />
                    <div className="flex gap-4 mr-4 items-center">
                        <div className="flex flex-col gap-1 w-64 h-16">
                            <p className="mb-1">Notifications</p>
                            <TextField
                                className="w-64 rounded-lg h-10"
                                placeholder="Search notifications"
                                type="search"
                                size="small"
                                InputProps={{
                                    endAdornment: (
                                        <img
                                            src={`${imageBaseURL}/images/caas-admin-ui/icons/search.png`}
                                            alt="search"
                                            className="w-6 h-6"
                                        />
                                    ),
                                }}
                            />
                        </div>
                        <div className="flex flex-col gap-1 w-64 h-16">
                            <p className="mb-1">Type</p>
                            <CommonDropDown
                                className="bg-white w-48 h-12"
                                placeHolder="All"
                                options={cryptoOptions}
                                value={inputFields?.notificationType}
                                onChange={handleInputChange}
                                name="notificationType" /></div>
                        <div className="flex flex-col gap-1 w-64 h-16">
                            <p className="mb-1">Status</p>
                            <CommonDropDown
                                className="bg-white w-48 h-12"
                                placeHolder="All"
                                options={cryptoOptions}
                                value={inputFields?.notificationStatus}
                                onChange={handleInputChange}
                                name="notificationStatus" /></div>
                        <div className="flex flex-col gap-1 w-64 h-16">
                            <p className="mb-1">Date</p>
                            <div className="flex border pl-2.5 border-[#b3b9c4] gap-2 rounded items-center h-11" onClick={() => document.getElementById("datepicker-input")?.focus()}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
                                </svg>
                                <Datepicker inputId="datepicker-input"
                                    onChange={handleDateChange}
                                    containerClassName="flex relative w-64 h-[39px] date-picker-container"
                                    asSingle={true}
                                    displayFormat={"YYYY-MM-DD"}
                                    placeholder="All"
                                    useRange={false}
                                    value={date}
                                    inputClassName="w-4/5 border-none outline-none notification-date-picker-input"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="filter-container flex flex-wrap gap-4">
                        {filterItems?.map((filterItem, index) => (
                            <div key={index} className="filter-item flex items-center gap-2 p-1 rounded-md bg-[#F4F4F5]">
                                <p className="filter-label font-medium leading-4 text-sm text-gray-600">
                                    {filterItem?.label}: {filterItem?.value}
                                </p>
                                <Close
                                    className="cursor-pointer w-4  h-4"
                                    fontSize="16px"
                                />
                            </div>
                        ))}
                        <button className="text-blue-500">Reset all({filterItems?.length})</button>
                    </div>
                </Box>
                <div className="flex flex-col h-[70vh]">
                    <div className="h-5.5/6 overflow-y-auto">
                        <CommonTable headers={tableHeaders}
                            renderRow={renderRow}
                            data={dummyData}
                        />
                    </div>
                    <CommonPagination setPage={setPage} page={page} />
                </div>
            </Box> : null}
            <Box className="flex-1">
                <TransactionDetails handleZoomClick={handleZoomClick} zoomIn={zoomIn} />
            </Box>
        </Box>
    );
};
const renderRow = (row) => {
    return (
        <>
            <StyledTableCell className="max-w-[150px] w-[150px]">
                {row?.Event ? row?.Event : "NA"}
            </StyledTableCell>
            <StyledTableCell className="max-w-[150px] w-[150px]" align="left">
                {row?.Status ? row?.Status : "NA"}
            </StyledTableCell>
            <StyledTableCell className="max-w-[150px] w-[150px]" align="left">
                {row?.Date ? row?.Date : "NA"}
            </StyledTableCell>
            <StyledTableCell className="w-[125px] max-w-[125px] overflow-hidden whitespace-nowrap truncate" align="left">
                {row?.Account ? row?.Account : "NA"}
            </StyledTableCell>
            <StyledTableCell className="w-[440px] max-w-[440px] overflow-hidden whitespace-nowrap truncate" align="left">
                {row?.Description ? row?.Description : "NA"}
            </StyledTableCell>
        </>
    );
};
export default memo(Notifications);
