import { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import "./styles/datePicker.css";
import moment from "moment";

const CommonDatePicker = (props) => {
  const { setDateRange } = props;

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleChange = (date) => {
    const { startDate, endDate } = date;
    if (startDate && endDate) {
      const startDateTime = moment.utc(startDate).startOf("day"); // 00:00 UTC
      const endDateTime = moment.utc(endDate).endOf("day"); // 23:59 UTC
      setValue(date);
      setDateRange(startDateTime.toISOString(), endDateTime.toISOString());
    }
  };

  return (
    <div className="border-[1px] rounded-md">
      <Datepicker value={value} onChange={handleChange} />
    </div>
  );
};

export default CommonDatePicker;
