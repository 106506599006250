import axios from "axios";
import configureAppStore from "../redux/store.js";
export const axiosInstance = axios.create();

const apiRequest = async (
  endpoint,
  method = "GET",
  data = null,
  headers = {}
) => {
  const store = configureAppStore();
  const state = await store.getState();
  let apiBaseUrl = await state.config.apiUrl;
  const url = `${apiBaseUrl}/restapi${endpoint}`;

  data = {
    ...data,
    token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
  };
  const options = {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    data,
  };

  try {
    const response = await axiosInstance(options);
    return { success: true, data: response.data };
  } catch (error) {
    if (error?.response?.status === 403) {
      localStorage.clear();
    } else if (error.response) {
      console.error("API call error response:", error.response.data);
      return {
        success: false,
        error:
          error.response?.data?.err ||
          error?.response?.data?.msg ||
          "Something went wrong",
      };
    } else {
      console.error("API call error:", error.message);
      return { success: false, error: error.message };
    }
  }
};

export default apiRequest;
